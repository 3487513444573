// AuthRoute.module.scss
.authRouteContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    .buttons {
        display: flex;
        justify-content: end;
    }
}
